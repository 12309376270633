import {
  Box,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  BusinessEntityType,
  GetBusinessEntityDetailsQuery,
  GoodDistributionInput,
  GoodInput,
} from "../../../../graphql/generated";
import InputAdornment from "@mui/material/InputAdornment";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import { sum } from "lodash";
import goodUnitLabel from "../../../../utils/labels/goodUnitsLabel";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../../common/LoadingOverlay";
import useDialog from "../../../../utils/hooks/useDialog";
import { useMemo, useState } from "react";
import BestBuyModal from "../../../best-buy/BestBuyModal/BestBuyModal";
import BusinessEntitySelectContainer from "../BusinessEntitySelect";

export type ReceiverGoodsListProps = {
  shippedGoods: Array<GoodInput>;
  receivedGoods: Array<GoodDistributionInput>;
  shipmentLocation: ShipmentLocationInputData & {
    persistedReceivedGoods?: Array<GoodDistributionInput>;
  };
  receiverDetails: GetBusinessEntityDetailsQuery["businessEntityById"] | null;
  receiverDetailsLoading?: boolean;
  onChange: (receivedGoods: Array<GoodDistributionInput>) => void;
  bestBuyOptionEnabled?: boolean;
  inlineShipperSelection?: boolean;
};

export interface Columns {
  label: string;
  field: string;
  align?: "left" | "right" | "center";
  disablePadding?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function ReceiverGoodsList({
  shippedGoods,
  receivedGoods,
  shipmentLocation,
  onChange,
  receiverDetails,
  receiverDetailsLoading,
  bestBuyOptionEnabled = true,
  inlineShipperSelection = false,
}: ReceiverGoodsListProps) {
  const storageFacilities = receiverDetails?.storageFacilities;
  const { t } = useTranslation(["common", "orders"]);

  const columns: Array<Columns> = (
    [
      {
        label: t("common:goods", "Goods"),
        field: "label",
        disablePadding: true,
      },
      {
        label: t("common:quantity", "Quantity"),
        field: "quantity",
        align: inlineShipperSelection ? "left" : "right",
      },
    ] as Columns[]
  ).concat(
    inlineShipperSelection
      ? [
          {
            label: t("orders:shipper", "Shipper"),
            field: "shipper",
          },
          {
            label: t("orders:supplier", "Supplier"),
            field: "supplier",
          },
          {
            label: t("orders:pinCode", "PIN Code"),
            field: "pinCode",
          },
        ]
      : []
  );

  const toggleGood = (goodId: string, toggled: boolean) => {
    const shippedGood = shippedGoods.find((good) => good._id === goodId);
    if (!shippedGood) {
      return;
    }

    if (toggled) {
      const shippedQuantity = shippedGood.quantity;
      const alreadyDeliveredQuantity = sum(
        receivedGoods
          .filter((rg) => rg.goodId === goodId)
          .map((receivedGood) => receivedGood.quantity || shippedGood.quantity)
      );
      const myReceivedGood = shipmentLocation.persistedReceivedGoods?.find(
        (receivedGood) => receivedGood.goodId === goodId
      );
      const myReceivedQty = myReceivedGood
        ? myReceivedGood.quantity || shippedGood.quantity
        : 0;
      const storageFacility = storageFacilities?.find(
        (sf) => sf.commodityId === shippedGood.goodProfileId
      );
      onChange(
        receivedGoods.concat({
          goodId,
          quantity:
            shippedQuantity === Infinity
              ? 1
              : Math.max(
                  shippedQuantity - alreadyDeliveredQuantity + myReceivedQty,
                  0
                ),
          goodProfileId: shippedGood.goodProfileId,
          shipperId: receiverDetails?.defaultShipperId || null,
          supplierId: storageFacility?.defaultSupplierId || null,
        })
      );
    } else {
      onChange(
        receivedGoods.filter((receivedGood) => receivedGood.goodId !== goodId)
      );
    }
  };

  const setReceivedGoodQuantity = (goodId: string, quantity: number | null) => {
    const shippedGood = shippedGoods.find((good) => good._id === goodId);
    if (!shippedGood) {
      return;
    }
    onChange(
      receivedGoods.map((receivedGood) => {
        if (receivedGood.goodId === goodId) {
          return {
            ...receivedGood,
            quantity:
              quantity === null
                ? null
                : Math.max(Math.min(quantity, shippedGood.quantity), 1),
          };
        }
        return receivedGood;
      })
    );
  };

  const onReceivedGoodChange = (receivedGood: GoodDistributionInput) => {
    onChange(
      receivedGoods.map((rg) =>
        rg.goodId === receivedGood.goodId ? receivedGood : rg
      )
    );
  };

  if (receiverDetailsLoading) {
    return <LoadingOverlay loading={receiverDetailsLoading} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="goodList">
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox"></StyledTableCell>
              {columns.map((col, index) => (
                <StyledTableCell
                  key={index}
                  padding={col.disablePadding ? "none" : "normal"}
                  align={col.align}
                >
                  {col.label}
                </StyledTableCell>
              ))}
              {bestBuyOptionEnabled ? (
                <StyledTableCell
                  key={columns.length - 1}
                  align="right"
                  padding="normal"
                >
                  Actions
                </StyledTableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {shippedGoods.map((good, goodIndex) => {
              const receivedGood = receivedGoods.find(
                (receivedGood) => receivedGood.goodId === good._id
              );
              const hasStorageFacility =
                !storageFacilities?.length ||
                storageFacilities.some(
                  (storageFacility) =>
                    storageFacility.commodityId === good.goodProfileId
                );
              if (good.goodProfileId && !hasStorageFacility) {
                return null;
              }

              return (
                <ReceiverGoodsItem
                  key={good._id}
                  receivedGood={receivedGood}
                  goodIndex={goodIndex}
                  toggleGood={toggleGood}
                  good={good}
                  setReceivedGoodQuantity={setReceivedGoodQuantity}
                  onChange={onReceivedGoodChange}
                  shipmentLocation={shipmentLocation}
                  bestBuyOptionEnabled={bestBuyOptionEnabled}
                  inlineShipperSelection={inlineShipperSelection}
                  customerId={receiverDetails?.parentBusinessEntityId}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

type ReceiverGoodsItemProps = {
  receivedGood: GoodDistributionInput | undefined;
  goodIndex: number;
  toggleGood: (goodId: string, toggled: boolean) => void;
  good: GoodInput;
  setReceivedGoodQuantity: (goodId: string, quantity: number | null) => void;
  onChange: (receivedGood: GoodDistributionInput) => void;
  shipmentLocation: ShipmentLocationInputData;
  bestBuyOptionEnabled?: boolean;
  inlineShipperSelection?: boolean;
  customerId: string;
};

const ReceiverGoodsItem = ({
  receivedGood,
  goodIndex,
  toggleGood,
  good,
  setReceivedGoodQuantity,
  onChange,
  shipmentLocation,
  bestBuyOptionEnabled,
  inlineShipperSelection,
  customerId,
}: ReceiverGoodsItemProps) => {
  const [isBestBuyOpen, setIsBestBuyOpen] = useState(false);
  const { showDialog } = useDialog();
  const { t } = useTranslation(["common", "orders"]);

  const purchase = useMemo(
    () => ({
      customerId,
      date: shipmentLocation.timeWindows[0]?.fromDate || "",
      dropoffLocation: shipmentLocation.location,
      goodProfileId: good.goodProfileId || "",
      quantity: receivedGood?.quantity || 0,
    }),
    [customerId, shipmentLocation, good, receivedGood]
  );

  return (
    <>
      <TableRow role="checkbox" tabIndex={-1} selected={!!receivedGood}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={!!receivedGood}
            onChange={(event, checked) => toggleGood(good._id, checked)}
            inputProps={{
              "aria-label": `${good.label} checkbox`,
            }}
          />
        </TableCell>
        <TableCell scope="row" padding="none">
          {good.label}
        </TableCell>
        <TableCell
          align="right"
          sx={{ width: inlineShipperSelection ? "15%" : "50%" }}
        >
          <TextField
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>{goodUnitLabel(good.unit)}</Typography>
                </InputAdornment>
              ),
            }}
            size="small"
            disabled={!receivedGood}
            value={receivedGood?.quantity || ""}
            placeholder={
              good.quantity === Infinity ? undefined : String(good.quantity)
            }
            inputProps={{
              "aria-label": `${good.label} quantity`,
              style: {
                textAlign: "right",
              },
            }}
            onChange={(event) => {
              setReceivedGoodQuantity(
                good._id,
                parseInt(event.target.value) || null
              );
            }}
            // helperText={kgToLocalWeight(good.weight || 0).toFixed(2)}
            name="commodity-quantity"
            id={`commodity-quantity-${goodIndex}`}
          />
        </TableCell>
        {inlineShipperSelection ? (
          <>
            <TableCell sx={{ width: "20%" }}>
              <BusinessEntitySelectContainer
                businessEntityType={BusinessEntityType.Shipper}
                allowAddress={false}
                value={receivedGood?.shipperId || null}
                onChange={(shipperId) => {
                  if (!receivedGood) {
                    return;
                  }
                  onChange({
                    ...receivedGood,
                    shipperId,
                  });
                }}
              />
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              <BusinessEntitySelectContainer
                businessEntityType={BusinessEntityType.Supplier}
                allowAddress={false}
                value={receivedGood?.supplierId || null}
                inputProps={{
                  required: false,
                }}
                onChange={(supplierId) => {
                  if (!receivedGood) {
                    return;
                  }
                  onChange({
                    ...receivedGood,
                    supplierId,
                  });
                }}
              />
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              <TextField
                fullWidth
                label={t("orders:pinCode", "Pin Code")}
                name="pinCode"
                value={receivedGood?.pinCode || ""}
                size="small"
                onChange={(event) => {
                  if (!receivedGood) {
                    return;
                  }
                  onChange({
                    ...receivedGood,
                    pinCode: event.target.value,
                  });
                }}
              />
            </TableCell>
          </>
        ) : null}
        {bestBuyOptionEnabled ? (
          <TableCell>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {!!receivedGood ? (
                <Button
                  variant="contained"
                  color="accent"
                  onClick={() => {
                    if (
                      shipmentLocation.receiver &&
                      good.goodProfileId &&
                      shipmentLocation.timeWindows[0]?.fromDate
                    ) {
                      setIsBestBuyOpen(true);
                    } else {
                      showDialog({
                        type: "primary",
                        title: t("common:error.title", "Error"),
                        description: t(
                          "orders:bestbuy.requirementError",
                          "Please, make sure to select a delivery date"
                        ),
                      });
                    }
                  }}
                >
                  {t("orders:bestbuy.title", "TrueSource")}
                </Button>
              ) : null}
            </Box>
          </TableCell>
        ) : null}
      </TableRow>
      {isBestBuyOpen &&
      receivedGood?.goodProfileId &&
      shipmentLocation.timeWindows[0]?.fromDate ? (
        <BestBuyModal
          open={isBestBuyOpen}
          purchase={purchase}
          onClose={() => setIsBestBuyOpen(false)}
          onBestBuyItemSubmit={(bestBuyItem) => {
            if (
              bestBuyItem &&
              receivedGood?.goodProfileId &&
              bestBuyItem.supplierContract.shipper
            ) {
              onChange({
                ...receivedGood,
                shipperId: bestBuyItem.supplierContract.shipperId,
                supplierId: bestBuyItem.supplierContract.supplierId,
                unitPrice: bestBuyItem.unitPrice,
                pinCode: bestBuyItem.supplierContract.pinCode,
              });
            }
          }}
        />
      ) : null}
    </>
  );
};
